function checkScroll() {
  document.body.classList.toggle( 'scrolled', window.scrollY > 1 );
}
  
function setMainMarginTop() {
  const header = document.querySelector( '.transparent-header header > .e-div, .error404 header > .e-div' );
  const main = document.querySelector( 'main, .entry-content, .e-stn--404-section' );
  if ( header && main ) {
    main.style.setProperty( 'margin-top', -header.offsetHeight + 'px', 'important' );
  }
}

function addMenuHoveredClass() {
  document.querySelector( 'body' ).classList.add( 'menu-hover' );
  document.querySelector( '.e-div--header-wrapper .max-mega-menu' )?.classList.add( 'mega-menu-hover' );
}
  
function removeMenuHoveredClass() {
  document.querySelector( 'body' ).classList.remove( 'menu-hover' );
  document.querySelector( '.e-div--header-wrapper .max-mega-menu' )?.classList.remove( 'mega-menu-hover' );
}

function addDropdownHoveredClass() {
  document.querySelector( 'body' ).classList.add( 'menu-has-children-hover' );
}

function removeDropdownHoveredClass() {
  document.querySelector( 'body' ).classList.remove( 'menu-has-children-hover' );
}

function moveSubMenuToSecondColumn() {
  if ( !document.querySelector( '.mega-industries-grid .mega-sub-menu' ) ) {
    const firstColumnChildrenItems = document.querySelectorAll( '.industries-dropdown .mega-sub-menu > .mega-menu-column:first-of-type .mega-sub-menu > .mega-menu-item-has-children' ); // eslint-disable-line max-len
    const secondColumn = document.querySelector( '.industries-dropdown .mega-sub-menu > .mega-menu-column.mega-industries-grid' ); // eslint-disable-line max-len
    let currentActiveMenuItem = null;

    firstColumnChildrenItems.forEach( ( menuItem, index ) => {
      const subMenu = Array.from( menuItem.children ).find( child => child.classList.contains( 'mega-sub-menu' ) );
      if ( subMenu ) {
        secondColumn.appendChild( subMenu );

        if ( index === 0 ) {
          menuItem.classList.add( 'active' );
          subMenu.classList.add( 'active' );
          currentActiveMenuItem = menuItem;
        }

        menuItem.addEventListener( 'mouseenter', () => {
          currentActiveMenuItem?.classList.remove( 'active' );
          document.querySelector( '.mega-sub-menu.active' )?.classList.remove( 'active' );
          menuItem.classList.add( 'active' );
          subMenu.classList.add( 'active' );
          currentActiveMenuItem = menuItem;
        } );
      }
    } );
  }
}

function initializeSortableLists() {
  document.querySelectorAll( '.industries-grid ul' ).forEach( list => {
    const originalOrder = [...list.children];

    function sortList() {
      if ( window.innerWidth < 1200 ) {
        const items = [...list.children].filter( li => !li.classList.contains( 'mega-has-description' ) );
        items.sort( ( a, b ) => a.textContent.localeCompare( b.textContent ) );
        list.append( ...items );
      } else {
        list.append( ...originalOrder );
      }
    }

    ['DOMContentLoaded', 'resize'].forEach( evt => window.addEventListener( evt, sortList ) );
  } );
}

document.addEventListener( 'DOMContentLoaded', function () {
  const menuItems = document.querySelectorAll( '.e-div--header-wrapper .max-mega-menu > li.mega-menu-item:not(.orange-btn, .menu-search-icon, .mega-wpml-ls-menu-item)' ); /* eslint-disable-line max-len */
  const menuItemHasChildren = document.querySelectorAll( '.e-div--header-wrapper .max-mega-menu > li.mega-menu-item-has-children:not(.green-btn, .menu-search-icon, .mega-wpml-ls-menu-item)' ); /* eslint-disable-line max-len */
  const languageSwitcher = document.querySelector( '.e-div--header-wrapper .mega-wpml-ls-menu-item a' );
  const menu = document.querySelector( '.e-div--header-wrapper .max-mega-menu' );
  
  if ( languageSwitcher ) {
    languageSwitcher.setAttribute( 'href', '#' );
  }
  menuItems.forEach( item => {
    item.addEventListener( 'mouseenter', addMenuHoveredClass );
    menu.addEventListener( 'mouseleave', removeMenuHoveredClass );
  } );
  
  menuItemHasChildren.forEach( item => {
    item.addEventListener( 'mouseenter', addDropdownHoveredClass );
    item.addEventListener( 'mouseleave', removeDropdownHoveredClass );
  } );

  checkScroll();
  setMainMarginTop();
  moveSubMenuToSecondColumn();
  initializeSortableLists();
} );
window.onscroll = checkScroll;
  
window.onresize = function() {
  checkScroll();
  setMainMarginTop();
};