import { slideDown, slideUp, slideToggle, toggleClass } from './common';
const facetFilerAccordion = () => {
  const accWrapper = '.e-div--rgen-facet-filter-acc';
  const activeClass = 'e-div--active';
  const handleElement = 'h6';
  const toggleBody = '.facetwp-facet';
  const accList = Array.from( document.querySelectorAll( accWrapper ) );

  if( accList.length === 0 ) return;
  
  for ( const element of accList ) {
    if( element.classList.contains( activeClass ) ) {
      slideDown( element.querySelector( toggleBody ) );
    }else {
      slideUp( element.querySelector( toggleBody ) );
    }
    const handle = element.querySelector( handleElement );
    handle.addEventListener( 'click', () => {
      slideToggle( handle.nextElementSibling );
      toggleClass( element, activeClass );
    } );
  }
  
};

const hideFiltersWhenEmpty = () => {
  document.querySelectorAll( '.facetwp-type-fselect .fs-options' ).forEach( element => {
    const facetParent = element.closest( '.facetwp-facet' );
    if ( facetParent ) {
      if ( element.querySelectorAll( 'div' ).length === 0 ) {
        facetParent.style.display = 'none';
      } else {
        facetParent.style.display = 'block';
      }
    }
  } );
};

const hideCheckboxFiltersWhenEmpty = () => {
  const allFacetOptions = document.querySelectorAll( '.facetwp-type-checkboxes' );

  allFacetOptions.forEach( element => {
    if ( element.querySelectorAll( '.facetwp-checkbox' ).length === 0 ) {
      const parentDiv = element.closest( '.e-div' );
      if ( parentDiv ) {
        parentDiv.remove();
      }
    }
  } );
};

document.addEventListener( 'DOMContentLoaded', () => {
  facetFilerAccordion();
} );

document.addEventListener( 'facetwp-loaded', () => {
  hideCheckboxFiltersWhenEmpty();
  hideFiltersWhenEmpty();
} );
