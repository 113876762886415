document.addEventListener( 'DOMContentLoaded', () => {
  const notificationBar = document.querySelector( '.b3rg-notification' );
    
  if ( !notificationBar ) return;
  
  // Show the notification bar on window load
  window.addEventListener( 'load', () => {
    notificationBar.style.visibility = 'visible';
  } );
} );
  