( () => {
  const dualLoopCarousel = {
  
    // Make cards clickable
    linkCards: ( cards ) => {
      cards.forEach( card => {
        const link = card.querySelector( 'a' ) ?? false;
        if ( link ) {
          link.addEventListener( 'click', ( e ) => {
            e.stopPropagation();
          } );
          card.addEventListener( 'click', () => {
            link.click();
          } );
        }
      } );
    },
  
    // Stop carousel on link hover
    stopOnHover: ( carouselCards ) => {
      carouselCards.forEach( card => {
        if ( card.querySelector( 'a' ) ) {
          card.addEventListener( 'mouseenter', ( e ) => {
            e.preventDefault();
            card.classList.add( 'hovered' );
            card.closest( '.e-div--logo-carousel' ).classList.add( 'pause' );
          } );
          card.addEventListener( 'mouseleave', ( e ) => {
            e.preventDefault();
            card.classList.remove( 'hovered' );
            card.closest( '.e-div--logo-carousel' ).classList.remove( 'pause' );
          } );
        }
      } );
    },
  
    // Animate carousel
    animate: () => {
      const carousels = document.querySelectorAll( '.e-div--logo-carousel' );
      if ( carousels.length === 0 ) return;
    
      carousels.forEach( ( carousel ) => {
        const cards = carousel.querySelectorAll( '.e-div--logo-card' );
        if ( cards.length <= 4 ) return;

        const initialTrack = carousel.querySelector( ':scope > .e-div' );
        if ( !initialTrack ) return;
  
        // Clone tracks
        const numClones = Math.ceil( carousel.offsetWidth / initialTrack.offsetWidth ) * 5;
        Array.from( { length: numClones } ).forEach( () => {
          carousel.appendChild( initialTrack.cloneNode( true ) );
        } );
    
        const clonedCarousels = carousel.querySelectorAll( ':scope > .e-div' );
        const carouselCards = carousel.querySelectorAll( '.e-crd--carousel-card' );
        dualLoopCarousel.linkCards( cards );
        dualLoopCarousel.stopOnHover( carouselCards );
        
        clonedCarousels.forEach( clonedCarousel => {
          clonedCarousel.style.animationDuration = `${35 - numClones}s`;
        } );
      } );
    }
  };
  
  function linkSlider() {
    const dualCarousel = document.querySelector( '.e-stn--dual-loop-carousel' );
  
    if ( dualCarousel ) {
      dualCarousel.addEventListener( 'mouseover', ( event ) => {
        if ( event.target.matches( '.e-stn--dual-loop-carousel a img' ) ) {
          dualCarousel.classList.add( 'pause-slider' );
        }
      } );
  
      dualCarousel.addEventListener( 'mouseout', ( event ) => {
        if ( event.target.matches( '.e-stn--dual-loop-carousel a img' ) ) {
          dualCarousel.classList.remove( 'pause-slider' );
        }
      } );
    }
  }
    
  dualLoopCarousel.animate();
  linkSlider();
} ) ();