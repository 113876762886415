/* eslint-disable no-unused-vars */
import { Fancybox } from '@fancyapps/ui';

document.addEventListener( 'DOMContentLoaded' , function() { 
  ( () => {
    const LeadershipPost = document.querySelector( '.e-stn--common-our-leaders' );
    if ( !LeadershipPost ) {
      return false;
    }
    const clickCard = document.querySelectorAll( '.e-sgp--list-item a[data-fancybox]' );
    const queryString = window.location.search;
    const pathName = window.location.pathname;
    const urlParams = new URLSearchParams( queryString );
    const leaderName = urlParams.get( 'lname' );
  
    Fancybox.bind( '.e-sgp--list-item a[data-fancybox]', {
      on: {
        initLayout: fancybox => {
          document.body.classList.add( 'body-scroll-diasable' );
          fancybox.$container.classList.add( 'leadership-popup' );
        },
        ready:fancybox => {
          fancybox.$container.classList.add( 'slide-in' );
        },
        closing: fancybox => {
          document.body.classList.remove( 'body-scroll-diasable' );
          window.history.pushState( 'obj', 'newtitle', pathName );
        },
      }
    } );
    
    clickCard.forEach( item => {
      item.addEventListener( 'click', function ( e ) {
        const urlslug = e.currentTarget.getElementsByClassName( 'e-sgp__inner' )[0].dataset.slug;
        if ( urlslug ) {
          const currentPath = window.location.pathname;
          const basePath = currentPath.endsWith( '/' ) ? currentPath : currentPath + '/';
          window.history.pushState( 'obj', 'newtitle', basePath + urlslug );
        }
      } );
    } );
  
    if ( leaderName ) {
      const clickElement = document.querySelector(
        `.e-sgp__inner[data-slug="${leaderName}"]`
      )?.closest( 'a' );
      if ( clickElement ) {
        setTimeout( () => {
          clickElement.click();
        }, 500 );
      }
    }
  } ) ();
} );
