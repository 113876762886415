let observer = new MutationObserver( function ( mutationsList ) {
  mutationsList.forEach( function ( mutation ) {
    if ( mutation.type === 'childList' ) {
      let caseStudyCards = document.querySelectorAll( '.case-study-card' );
      let mapBtns = document.querySelectorAll( '.map-btn a' );
      if ( mapBtns.length > 0 ) {
        let mapBtnLink = mapBtns[mapBtns.length - 1];
        let href = mapBtnLink.getAttribute( 'href' );

        if ( href ) {
          caseStudyCards.forEach( function ( card ) {
            if ( !card.closest( '.wrapped-link' ) ) {
              let wrapper = document.createElement( 'a' );
              wrapper.href = href;
              wrapper.classList.add( 'wrapped-link' );
              card.parentNode.insertBefore( wrapper, card );
              wrapper.appendChild( card );
            }
          } );
        }
      }
    }
  } );
} );
observer.observe( document.body, { childList: true, subtree: true } );
