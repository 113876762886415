const headerHeight = 185; // Sticky header height
const mobileOffset = 150; // Set 110px offset for screens < 992px
  
const scrollToSection = ( targetSection ) => {
  if ( targetSection ) {
    if ( window.innerWidth < 992 ) {
      document.querySelectorAll( '.e-stn--card-scroll .e-col section' ).forEach( section => {
        section.classList.remove( 'active' );
      } );
  
      targetSection.classList.add( 'active' );
  
      setTimeout( () => {
        targetSection.scrollIntoView( {
          behavior: 'smooth',
          block: 'start',
        } );
  
        window.scrollTo( {
          top: window.scrollY + targetSection.getBoundingClientRect().top - mobileOffset,
          behavior: 'smooth',
        } );
      }, 50 ); 
    } else {
      const rect = targetSection.getBoundingClientRect();
      window.scrollTo( {
        behavior: 'smooth',
        top: rect.top + window.scrollY - headerHeight,
      } );
    }
  }
};
  
// Smooth scrolling on clicking links
document.querySelectorAll( '.e-stn--card-scroll .e-col:first-child li a' ).forEach( anchor => {
  anchor.addEventListener( 'click', function ( e ) {
    e.preventDefault();
    const targetId = this.getAttribute( 'href' ).substring( 1 );
    const targetSection = document.getElementById( targetId );
    scrollToSection( targetSection );
  } );
} );
  
// Highlight left column link based on scroll position
const sections = document.querySelectorAll( '.e-stn--card-scroll .e-col section' );
const links = document.querySelectorAll( '.e-stn--card-scroll .e-col:first-child li a' );
  
window.addEventListener( 'scroll', () => {
  let currentSection = null;
  
  sections.forEach( section => {
    const rect = section.getBoundingClientRect();
    if ( rect.top <= window.innerHeight * 0.3 && rect.bottom >= window.innerHeight * 0.3 ) {
      currentSection = section;
    }
  } );
  
  links.forEach( link => {
    const href = link.getAttribute( 'href' );
    const targetId = href.substring( 1 );
    const section = document.getElementById( targetId );
  
    if ( currentSection && section && section === currentSection ) {
      link.classList.add( 'active' );
    } else {
      link.classList.remove( 'active' );
    }
  } );
} );
  
const appendLinksToSections = () => {
  if ( window.innerWidth < 992 ) {
    sections.forEach( section => {
      const targetId = section.id;
      const link = document.querySelector( `.e-stn--card-scroll .e-col:first-child li a[href="#${targetId}"]` );
      if ( link && !section.contains( link ) ) {
        section.insertBefore( link, section.firstChild );
      }
    } );
  }
};
  
const setDefaultActiveSection = () => {
  if ( window.innerWidth < 992 ) {
    const firstSection = sections[0];
    if ( firstSection ) {
      firstSection.classList.add( 'active' );
    }
  }
};
  
setDefaultActiveSection();
appendLinksToSections();
  
window.addEventListener( 'resize', () => {
  appendLinksToSections();
  setDefaultActiveSection();
} );


let observer = new IntersectionObserver( function ( entries ) {
  entries.forEach( entry => {
    if ( !entry.isIntersecting ) {
      document.querySelector( '.e-stn--card-scroll' ).classList.remove( 'g-add' );
    } else {
      document.querySelector( '.e-stn--card-scroll' ).classList.add( 'g-add' );
    }
  } );
}, {
  rootMargin: '50px',
  threshold: 0.1
} );

const cardScrollElement = document.querySelector( '.e-stn--card-scroll' );

if ( cardScrollElement ) {
  observer.observe( cardScrollElement );
}
