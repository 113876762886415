function mobileArrowIcon() {
  const mediaQuery = window.matchMedia( '(max-width: 1199px)' );
  const primaryMenu = document.querySelector( '#mega-menu-primary_menu' );

  if ( mediaQuery.matches ) {
    enableMobileMenu( primaryMenu );
  } else {
    disableMobileMenu();
  }
}

document.addEventListener( 'DOMContentLoaded', mobileArrowIcon );

let resizeTimeout;
window.onresize = function () {
  clearTimeout( resizeTimeout );
  resizeTimeout = setTimeout( mobileArrowIcon, 200 );
};

function enableMobileMenu( primaryMenu ) {
  const firstTypeColumnItem = document.querySelectorAll(
    '#mega-menu-primary_menu > .mega-menu-item-has-children .mega-menu-column:first-of-type' /* eslint-disable-line max-len */
  );

  addStaticLinkContainer( primaryMenu );
  firstTypeColumnItem.forEach( addMobileBackButton );
  addEventListenersToButtons();
  addEventListenersToLinks( primaryMenu );
}

function disableMobileMenu() {
  document.querySelectorAll( '.mobile-back-btn' ).forEach( ( mbutton ) => {
    mbutton.removeEventListener( 'click', handleBackButtonClick );
    mbutton.remove();
  } );
  const existingUlContainer = document.querySelector( '#mega-menu-primary_menu > li > .static-link-container' );
  if ( existingUlContainer ) {
    removeStaticLinkContainer( existingUlContainer );
  }
}

function addStaticLinkContainer( primaryMenu ) {
  const searchIcon = primaryMenu.querySelector( '.mega-menu-search-icon' );
  const greenButton = primaryMenu.querySelector( '.mega-green-btn' );
  const languageDrop = primaryMenu.querySelector( '.mega-wpml-ls-menu-item' );

  const newLiContainer = document.createElement( 'li' );
  const newUlContainer = document.createElement( 'ul' );
  newUlContainer.classList.add( 'static-link-container' );
  newLiContainer.appendChild( newUlContainer );
  if( !document.querySelector( '#mega-menu-primary_menu > li > .static-link-container' ) ) {
    newUlContainer.appendChild( searchIcon );
    newUlContainer.appendChild( greenButton );
    newUlContainer.appendChild( languageDrop );
    primaryMenu.appendChild( newLiContainer );
  }
}

function removeStaticLinkContainer( existingUlContainer ) {
  const primaryMenu = document.querySelector( '#mega-menu-primary_menu' );
  const searchIcon = existingUlContainer.querySelector( '.mega-menu-search-icon' );
  const greenButton = existingUlContainer.querySelector( '.mega-green-btn' );
  const languageDrop = existingUlContainer.querySelector( '.mega-wpml-ls-menu-item' );

  primaryMenu.appendChild( searchIcon );
  primaryMenu.appendChild( greenButton );
  primaryMenu.appendChild( languageDrop );
  existingUlContainer.parentElement.remove(); 
}

function addMobileBackButton( item ) {
  if ( !item.querySelector( '.mobile-back-btn' ) ) {
    const span = document.createElement( 'span' );
    span.className = 'mobile-back-btn';
    item.prepend( span );
  }
}

function addEventListenersToButtons() {
  document.querySelectorAll( '.mobile-back-btn' ).forEach( ( mbutton ) => {
    mbutton.addEventListener( 'click', handleBackButtonClick );
  } );
}

function addEventListenersToLinks( primaryMenu ) {
  document.querySelectorAll( '#mega-menu-primary_menu > .mega-menu-item-has-children a' ).forEach( ( link ) => {
    link.addEventListener( 'click', ( e ) => handleSubMenuClick( e, primaryMenu ) );
  } );
}

function handleBackButtonClick( e ) {
  const menuItem = e.target.closest( '.mega-menu-megamenu' );
  const subMenu = menuItem.querySelector( '.mega-sub-menu' );
  toggleSubMenu( subMenu, false );

  const primaryMenu = document.querySelector( '#mega-menu-primary_menu' );
  primaryMenu.classList.remove( 'sub-menu-enabled' );
  menuItem.classList.remove( 'mega-toggle-on' );
  menuItem.querySelector( 'a.mega-menu-link' ).setAttribute( 'aria-expanded', 'false' );
}

function handleSubMenuClick( e, primaryMenu ) {
  const menuItem = e.target.closest( '.mega-menu-megamenu' );
  const subMenu = menuItem.querySelector( '.mega-sub-menu' );
  toggleSubMenu( subMenu, true );
  primaryMenu.classList.add( 'sub-menu-enabled' );
}

function toggleSubMenu( subMenu, enable ) {
  if ( subMenu ) {
    setTimeout( () => {
      Object.assign( subMenu.style, {
        transform: enable ? 'translateY(0)' : 'translateY(-50px)',
        opacity: enable ? '1' : '0',
        visibility: enable ? 'visible' : 'hidden',
      } );
    }, 300 );
  }
}
