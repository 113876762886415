// Add 'active' class to all .e-crd elements on page load
document.querySelectorAll( '.e-stn--pull-down-cards .e-crd' ).forEach( card => {
  card.classList.add( 'f-active' );
} );

// Event listener for .e-crd click functionality
document.querySelectorAll( '.e-stn--pull-down-cards .e-crd' ).forEach( card => {
  card.addEventListener( 'click', function () {
    // Remove 'active' class from all cards
    document.querySelectorAll( '.e-stn--pull-down-cards .e-crd' ).forEach( otherCard => {
      otherCard.classList.remove( 'active' );
      otherCard.classList.remove( 'f-active' );
    } );

    // Add 'active' class to the clicked card
    this.classList.add( 'active' );

    // Find the closest '.e-crd--content' sibling (next element)
    const content = this.nextElementSibling;

    // Ensure original content visibility
    document.querySelectorAll( '.e-crd--content' ).forEach( originalContent => {
      originalContent.classList.remove( 'show' );
    } );

    // Using optional chaining to check if content exists and has the 'e-crd--content' class
    content?.classList.contains( 'e-crd--content' ) && content.classList.add( 'show' );

    // Scroll to the original .e-crd--content with the 88px offset
    if ( window.innerWidth < 768 ) {
      if ( content ) {
        scrollToElementWithOffset( content, 105 );
      }
    }

    // Handle content cloning for .e-col--pull-down-content
    if ( window.innerWidth > 767 ) {
      if ( content ) {
        scrollToElementWithOffset( content, 0 );
      }
      const row = this.closest( '.e-stn--pull-down-cards .row' ); // Get the closest parent row
      const rightColumn = row.querySelector( '.e-col--pull-down-content' );

      if ( rightColumn ) {
        // Remove any existing cloned content
        rightColumn.querySelectorAll( '.e-crd--content' ).forEach( clonedContent => {
          rightColumn.removeChild( clonedContent );
        } );

        // Clone the content and append it to the .e-col--pull-down-content
        if ( content ) {
          const contentClone = content.cloneNode( true ); // Clone the content
          contentClone.classList.add( 'show' ); // Add the 'show' class for transition

          // Attach event listener to the close icon in the cloned content
          const closeIcon = contentClone.querySelector( '.e-icn-close' );
          closeIcon?.addEventListener( 'click', () => {
            // Remove the cloned content on close
            rightColumn.removeChild( contentClone );

            // Add 'active' class to all .e-crd elements
            document.querySelectorAll( '.e-stn--pull-down-cards .e-crd' ).forEach( card => {
              card.classList.add( 'active' );
              card.classList.add( 'f-active' );
            } );
          } );

          // Append the cloned content to the right column
          rightColumn.appendChild( contentClone );

          // Scroll to the cloned content with the 88px offset
          scrollToElementWithOffset( card, 105 );
        }
      }
    }
  } );
} );

// Event listener for close icons in original content
document.querySelectorAll( '.e-crd--content .e-icn-close' ).forEach( closeIcon => {
  closeIcon.addEventListener( 'click', function () {
    const content = this.closest( '.e-crd--content' );
    if ( content ) {
      content.classList.remove( 'show' ); // Hide the original content
    }

    // Add 'active' class to all .e-crd elements
    document.querySelectorAll( '.e-stn--pull-down-cards .e-crd' ).forEach( card => {
      card.classList.add( 'active' );
    } );
  } );
} );

// Scroll function with offset
function scrollToElementWithOffset( element, offset ) {
  const rect = element.getBoundingClientRect();
  const elementTop = rect.top + window.scrollY; // Get the position of the element using scrollY
  const scrollToPosition = elementTop - offset; // Apply the offset

  window.scrollTo( {
    top: scrollToPosition,
    behavior: 'smooth', // Smooth scroll
  } );
}
